<template>
  <div class="mainPage">
    <p class="pageTitle">Change Password</p>
    <div class="pageWrap">
      <Password class="pass-wrap"/>
    </div>
  </div>
</template>

<script>
import Password from './components/Password.vue'

export default {
  components: {Password}
}
</script>

